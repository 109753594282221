<template>
  <div>
    <img style="width: 100%;" alt="" src="@/assets/images/patientInfo.png" />
    <div class="ml-10 mr-10">
      <h2>{{ $t("Pleasecompleteyourregistrationtoaccessourservices") }}</h2>
      <div class="ml-10 mr-10 mt-10">
        <point v-for="item in points" :data="item" :key="item.title" />
      </div>
      <div class="w-full justify-center ">
        <div class="vx-row w-full con-img justify-center">
          <img
            src="@/assets/images/user.png"
            alt="user-img"
            width="70"
            height="70"
            class="rounded-full shadow-md cursor-pointer block m-4"
          />
          <div class=" position: absolute py-12 w-1">
            <input
              type="file"
              class="hidden"
              ref="uploadImgInput"
              @change="updateCurrImg"
              accept="image/*"
              @on-success="successUpload"
            />

            <vs-avatar
              @click="$refs.uploadImgInput.click()"
              color="primary"
              icon-pack="feather"
              icon="icon-upload"
              class="m-5"
            />
          </div>
        </div>
        <div class="vx-row w-full justify-center ">
          <!-- <vs-input
            data-vv-validate-on="blur"
            name="First Name"
            icon-pack="feather"
            label-placeholder="First Name"
            :placeholder="$t('First Name')"
            v-model="email"
            :icon-after="true"
            class="m-3 mt-3"
          />
          <vs-input
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            name="email"
            class=" m-3 mt-3"
            icon-no-border
            label-placeholder="Email"
            :placeholder="$t('Family Name ')"
            v-model="email"
            :icon-after="true"
          /> -->
          <div div class="vx-row w-full justify-center">
            <vs-input
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('HospitalName')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('maincontact')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('PhoneNumber')"
              v-model="email"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="email"
              class="w-full sm:w-1/4  xl:w-5/12  m-2 mt-2"
              :placeholder="$t('Country')"
              :icon-after="true"
            />
          </div>
          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="email"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('HospitalGroup')"
              v-model="email"
              :icon-after="true"
            />
          </div>

          <div div class="vx-row w-full justify-center">
            <vs-input
              v-validate="'required|email'"
              data-vv-validate-on="blur"
              name="Speciality"
              class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
              icon-no-border
              label-placeholder="Email"
              :placeholder="$t('Specialty')"
              v-model="email"
              :icon-after="true"
            />
          </div>

          <div class="vx-row w-full ">
            <div class="vx-col lg:w-3/4"></div>
            <vs-button
              color="rgb(62, 201, 214) "
              class="vx-col"
              type="filled"
              style="float: right;"
              >{{ $t("Next") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="vs-row space-between ">
      <vs-button color="rgb(62, 201, 214)  " type="filled">Next</vs-button>
    </div> -->
  </div>
</template>
<script>
// import point from "../../components/point.vue";
export default {
  components: {
    // point
  },
  data() {
    return {
      options: [],
      radios1: "luis"
    };
  },
  methods: {
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    }
  }
};
</script>
<style>

.textfont {
  font-family: "futuraMedium";
}
</style>
